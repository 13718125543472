body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::after {
  content: "";
  background-image: url("img/banner.jpg"); 
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;   
}

@media only screen and (max-width: 1920px) {
	body::after {
		content: "";
		background-image: none; 
	}
}

@media (max-width: 1281px) {
    .navbar-header {
        float: none;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
    .navbar-collapse.collapse.in { 
        display: block!important;
    }
    .collapsing {
        overflow: hidden!important;
    }
} 

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.imgbox {
	display: grid;
	height: 100%;
}
.center-fit {
	max-width: 100%;
	max-height: 100vh;
	margin: auto;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}
/* react header modifications */
.navbar-default{
	background-color: transparent;
	border-inline-end-width: 0px;
	border-inline-start-width: 0px;
	border-top-color: transparent;
	border-bottom-color: transparent;
}

.div_fieldname{
	width:110px;
	display:inline-block;
}
.div_fieldinput{
	width:150px;
	display:inline-block;
}
.input_tb{
  width:230px;
  padding:5px;
  margin-top:5px;
  margin-bottom:5px;
  color: #000000;
}

.myButton {
	padding:1px 10px;
	text-decoration:none;
}
.myButton:hover {
  text-decoration: none;
  color:#ffffff;
}
.myButton_blue {
  word-wrap: break-word;
	-moz-box-shadow:inset 0px 1px 3px 0px #9fb4f2;
	-webkit-box-shadow:inset 0px 1px 3px 0px #9fb4f2;
	box-shadow:inset 0px 1px 3px 0px #9fb4f2;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #7892c2), color-stop(1, #476e9e));
	background:-moz-linear-gradient(top, #7892c2 5%, #476e9e 100%);
	background:-webkit-linear-gradient(top, #7892c2 5%, #476e9e 100%);
	background:-o-linear-gradient(top, #7892c2 5%, #476e9e 100%);
	background:-ms-linear-gradient(top, #7892c2 5%, #476e9e 100%);
	background:linear-gradient(to bottom, #7892c2 5%, #476e9e 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#7892c2', endColorstr='#476e9e',GradientType=0);
	background-color:#7892c2;
	-moz-border-radius:5px;
	-webkit-border-radius:5px;
	border-radius:5px;
	border:1px solid #4e6096;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	text-decoration:none;
	text-shadow:0px -1px 0px #283966;
}
.myButton_blue:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #476e9e), color-stop(1, #7892c2));
	background:-moz-linear-gradient(top, #476e9e 5%, #7892c2 100%);
	background:-webkit-linear-gradient(top, #476e9e 5%, #7892c2 100%);
	background:-o-linear-gradient(top, #476e9e 5%, #7892c2 100%);
	background:-ms-linear-gradient(top, #476e9e 5%, #7892c2 100%);
	background:linear-gradient(to bottom, #476e9e 5%, #7892c2 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#476e9e', endColorstr='#7892c2',GradientType=0);
	background-color:#476e9e;
  color:#ffffff;
}
.myButton_red {
	-moz-box-shadow:inset 0px 1px 0px 0px #f7c5c0;
	-webkit-box-shadow:inset 0px 1px 0px 0px #f7c5c0;
	box-shadow:inset 0px 1px 0px 0px #f7c5c0;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #fc8d83), color-stop(1, #e4685d));
	background:-moz-linear-gradient(top, #fc8d83 5%, #e4685d 100%);
	background:-webkit-linear-gradient(top, #fc8d83 5%, #e4685d 100%);
	background:-o-linear-gradient(top, #fc8d83 5%, #e4685d 100%);
	background:-ms-linear-gradient(top, #fc8d83 5%, #e4685d 100%);
	background:linear-gradient(to bottom, #fc8d83 5%, #e4685d 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#fc8d83', endColorstr='#e4685d',GradientType=0);
	background-color:#fc8d83;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #d83526;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	text-decoration:none;
	text-shadow:0px 1px 0px #b23e35;
}
.myButton_red:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #e4685d), color-stop(1, #fc8d83));
	background:-moz-linear-gradient(top, #e4685d 5%, #fc8d83 100%);
	background:-webkit-linear-gradient(top, #e4685d 5%, #fc8d83 100%);
	background:-o-linear-gradient(top, #e4685d 5%, #fc8d83 100%);
	background:-ms-linear-gradient(top, #e4685d 5%, #fc8d83 100%);
	background:linear-gradient(to bottom, #e4685d 5%, #fc8d83 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#e4685d', endColorstr='#fc8d83',GradientType=0);
	background-color:#e4685d;
}

.myButton_grey {	-moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
	-webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ededed), color-stop(1, #dfdfdf));
	background:-moz-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
	background:-webkit-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
	background:-o-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
	background:-ms-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
	background:linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf',GradientType=0);
	background-color:#ededed;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#777777;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	text-decoration:none;
}
.myButton_grey:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #dfdfdf), color-stop(1, #ededed));
	background:-moz-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
	background:-webkit-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
	background:-o-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
	background:-ms-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
	background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed',GradientType=0);
	background-color:#dfdfdf;
}
.myButton_green {
  word-wrap: break-word;
	-moz-box-shadow:inset 0px 1px 0px 0px #9acc85;
	-webkit-box-shadow:inset 0px 1px 0px 0px #9acc85;
	box-shadow:inset 0px 1px 0px 0px #9acc85;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #74ad5a), color-stop(1, #68a54b));
	background:-moz-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:-webkit-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:-o-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:-ms-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:linear-gradient(to bottom, #74ad5a 5%, #68a54b 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#74ad5a', endColorstr='#68a54b',GradientType=0);
	background-color:#74ad5a;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #3b6e22;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	text-shadow:0px 1px 0px #92b879;
}
.myButton_green:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #68a54b), color-stop(1, #74ad5a));
	background:-moz-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:-webkit-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:-o-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:-ms-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:linear-gradient(to bottom, #68a54b 5%, #74ad5a 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#68a54b', endColorstr='#74ad5a',GradientType=0);
	background-color:#68a54b;
}

.myButton_inactive {
	-moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
	-webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ffffff), color-stop(1, #f6f6f6));
	background:-moz-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
	background:-webkit-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
	background:-o-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
	background:-ms-linear-gradient(top, #ffffff 5%, #f6f6f6 100%);
	background:linear-gradient(to bottom, #ffffff 5%, #f6f6f6 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f6f6f6',GradientType=0);
	background-color:#ffffff;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	color:#999999;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	text-decoration:none;
	text-shadow:0px 1px 0px #ffffff;
}

 
.myButton_new {
	word-wrap: break-word;
	  -moz-box-shadow:inset 0px 1px 3px 0px #9fb4f2;
	  -webkit-box-shadow:inset 0px 1px 3px 0px #9fb4f2;
	  box-shadow:inset 0px 1px 3px 0px #9fb4f2;
	  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #7892c2), color-stop(1, #476e9e));
	  background:-moz-linear-gradient(top, #7892c2 5%, #476e9e 100%);
	  background:-webkit-linear-gradient(top, #7892c2 5%, #476e9e 100%);
	  background:-o-linear-gradient(top, #7892c2 5%, #476e9e 100%);
	  background:-ms-linear-gradient(top, #7892c2 5%, #476e9e 100%);
	  background:linear-gradient(to bottom, #7892c2 5%, #476e9e 100%);
	  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#7892c2', endColorstr='#476e9e',GradientType=0);
	  background-color:#7892c2;
	  -moz-border-radius:5px;
	  -webkit-border-radius:5px;
	  border-radius:5px;
	  border:1px solid #4e6096;
	  display:inline-block;
	  cursor:pointer;
	  color:#ffffff;
	  font-family:Arial;
	  font-size:15px;
	  font-weight:bold;
	  text-decoration:none;
	  text-shadow:0px -1px 0px #283966;
	  padding:8px 5px;
  }
  .myButton_new:hover {
	  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #476e9e), color-stop(1, #7892c2));
	  background:-moz-linear-gradient(top, #476e9e 5%, #7892c2 100%);
	  background:-webkit-linear-gradient(top, #476e9e 5%, #7892c2 100%);
	  background:-o-linear-gradient(top, #476e9e 5%, #7892c2 100%);
	  background:-ms-linear-gradient(top, #476e9e 5%, #7892c2 100%);
	  background:linear-gradient(to bottom, #476e9e 5%, #7892c2 100%);
	  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#476e9e', endColorstr='#7892c2',GradientType=0);
	  background-color:#476e9e;
	color:#ffffff;
  }
  .myButton_del {
	  -moz-box-shadow:inset 0px 1px 0px 0px #f7c5c0;
	  -webkit-box-shadow:inset 0px 1px 0px 0px #f7c5c0;
	  box-shadow:inset 0px 1px 0px 0px #f7c5c0;
	  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #fc8d83), color-stop(1, #e4685d));
	  background:-moz-linear-gradient(top, #fc8d83 5%, #e4685d 100%);
	  background:-webkit-linear-gradient(top, #fc8d83 5%, #e4685d 100%);
	  background:-o-linear-gradient(top, #fc8d83 5%, #e4685d 100%);
	  background:-ms-linear-gradient(top, #fc8d83 5%, #e4685d 100%);
	  background:linear-gradient(to bottom, #fc8d83 5%, #e4685d 100%);
	  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#fc8d83', endColorstr='#e4685d',GradientType=0);
	  background-color:#fc8d83;
	  -moz-border-radius:6px;
	  -webkit-border-radius:6px;
	  border-radius:6px;
	  border:1px solid #d83526;
	  display:inline-block;
	  cursor:pointer;
	  color:#ffffff;
	  font-family:Arial;
	  font-size:15px;
	  font-weight:bold;
	  text-decoration:none;
	  text-shadow:0px 1px 0px #b23e35;
  }
  .myButton_del:hover {
	  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #e4685d), color-stop(1, #fc8d83));
	  background:-moz-linear-gradient(top, #e4685d 5%, #fc8d83 100%);
	  background:-webkit-linear-gradient(top, #e4685d 5%, #fc8d83 100%);
	  background:-o-linear-gradient(top, #e4685d 5%, #fc8d83 100%);
	  background:-ms-linear-gradient(top, #e4685d 5%, #fc8d83 100%);
	  background:linear-gradient(to bottom, #e4685d 5%, #fc8d83 100%);
	  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#e4685d', endColorstr='#fc8d83',GradientType=0);
	  background-color:#e4685d;
  }
  
.myButton_cancel {	-moz-box-shadow:inset 0px 1px 0px 0px #ffffff;
	-webkit-box-shadow:inset 0px 1px 0px 0px #ffffff;
	box-shadow:inset 0px 1px 0px 0px #ffffff;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #ededed), color-stop(1, #dfdfdf));
	background:-moz-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
	background:-webkit-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
	background:-o-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
	background:-ms-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
	background:linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf',GradientType=0);
	background-color:#ededed;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #dcdcdc;
	display:inline-block;
	cursor:pointer;
	color:#777777;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	text-decoration:none;
	padding:8px 5px;
}
.myButton_cancel:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #dfdfdf), color-stop(1, #ededed));
	background:-moz-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
	background:-webkit-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
	background:-o-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
	background:-ms-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
	background:linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed',GradientType=0);
	background-color:#dfdfdf;
}
  .myButton_edit {
	word-wrap: break-word;
	  -moz-box-shadow:inset 0px 1px 0px 0px #9acc85;
	  -webkit-box-shadow:inset 0px 1px 0px 0px #9acc85;
	  box-shadow:inset 0px 1px 0px 0px #9acc85;
	  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #74ad5a), color-stop(1, #68a54b));
	  background:-moz-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	  background:-webkit-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	  background:-o-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	  background:-ms-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	  background:linear-gradient(to bottom, #74ad5a 5%, #68a54b 100%);
	  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#74ad5a', endColorstr='#68a54b',GradientType=0);
	  background-color:#74ad5a;
	  -moz-border-radius:6px;
	  -webkit-border-radius:6px;
	  border-radius:6px;
	  border:1px solid #3b6e22;
	  display:inline-block;
	  cursor:pointer;
	  color:#ffffff;
	  font-family:Arial;
	  font-size:15px;
	  font-weight:bold;
	  text-shadow:0px 1px 0px #92b879;
  }
  .myButton_edit:hover {
	  background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #68a54b), color-stop(1, #74ad5a));
	  background:-moz-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	  background:-webkit-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	  background:-o-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	  background:-ms-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	  background:linear-gradient(to bottom, #68a54b 5%, #74ad5a 100%);
	  filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#68a54b', endColorstr='#74ad5a',GradientType=0);
	  background-color:#68a54b;
  }

.myButton_addorder {
word-wrap: break-word;
float: right;
	-moz-box-shadow:inset 0px 1px 0px 0px #9acc85;
	-webkit-box-shadow:inset 0px 1px 0px 0px #9acc85;
	box-shadow:inset 0px 1px 0px 0px #9acc85;
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #74ad5a), color-stop(1, #68a54b));
	background:-moz-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:-webkit-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:-o-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:-ms-linear-gradient(top, #74ad5a 5%, #68a54b 100%);
	background:linear-gradient(to bottom, #74ad5a 5%, #68a54b 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#74ad5a', endColorstr='#68a54b',GradientType=0);
	background-color:#74ad5a;
	-moz-border-radius:6px;
	-webkit-border-radius:6px;
	border-radius:6px;
	border:1px solid #3b6e22;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	text-shadow:0px 1px 0px #92b879;
}
.myButton_addorder:hover {
	background:-webkit-gradient(linear, left top, left bottom, color-stop(0.05, #68a54b), color-stop(1, #74ad5a));
	background:-moz-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:-webkit-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:-o-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:-ms-linear-gradient(top, #68a54b 5%, #74ad5a 100%);
	background:linear-gradient(to bottom, #68a54b 5%, #74ad5a 100%);
	filter:progid:DXImageTransform.Microsoft.gradient(startColorstr='#68a54b', endColorstr='#74ad5a',GradientType=0);
	background-color:#68a54b;
}


.CommandPanel_header {
	padding: 10px;
	margin-bottom: 10px;
	border: 0;
	line-height: 1;
	background-color:#FFFFFF;
}

.CommandPanel_subheader {
	padding: 10px;
	margin-bottom: 10px;
	border: 0;
	line-height: 1;
	background-color:#FFFFFF;
}
.activeloc_header {
	font-weight: bold;
}
.activeloc_wrapper {
	padding: 5px;
	margin-bottom: 10px;
	color:#999999;
	font-size:20pt;
	text-align:center;
	font-weight: bold;
	background: transparent;
}
.activeloc_home{
	font-size:8pt;
	color: #07920e;
	font-weight: bold;
	text-transform: uppercase; 
}
.margin_bottom {
	margin-bottom: 0px;
}
.select_notice {
	padding: 5px;
	border: 0;
	text-align: center;
	color:#FFFFFF;
	background-color:#0d5138;
}  
.navigation {
	padding: 0;
	margin: 0;
	border: 0;
	line-height: 1;
}

.navigation ul,
.navigation ul li,
.navigation ul ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.navigation ul {
	position: relative;
	z-index: 500;
	float: left;
}

.navigation ul li {
	float: left;
	min-height: 0.05em;
	line-height: 1em;
	vertical-align: middle;
	position: relative;
}

.navigation ul li.hover,
.navigation ul li:hover {
	position: relative;
	z-index: 510;
	cursor: default;
}

.navigation ul ul {
	visibility: hidden;
	position: absolute;
	top: 100%;
	left: 0px;
	z-index: 520;
	width: 100%;
}

.navigation ul ul li { float: none; }

.navigation ul ul ul {
	top: 0;
	right: 0;
}

/* .navigation ul li:hover > ul { visibility: visible; } */

.navigation ul ul {
	top: 0;
	left: 99%;
}

.navigation ul li { float: none; }

.navigation ul ul { margin-top: 0.05em; }

.navigation {
	width: 13em;
	background: #333333;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	zoom: 1;
}

.navigation:before {
	content: '';
	display: block;
}

.navigation:after {
	content: '';
	display: table;
	clear: both;
}

.navigation a {
	display: block;
	padding: 1em 1.3em;
	color: #ffffff;
	text-decoration: none;
	/* text-transform: uppercase; */
}

.navigation > ul { width: 13em; }

.navigation ul ul { width: 13em; }

.navigation > ul > li > a {
	border-right: 0.3em solid #34A65F;
	color: #ffffff;
}

.navigation > ul > li > a:hover { color: #ffffff; }

.navigation > ul > li a:hover,
.navigation > ul > li:hover a { background: #34A65F; }

.navigation li { position: relative; }

.navigation ul li.has-sub > a:after {
	content: '»';
	position: absolute;
	right: 1em;
}

.navigation ul ul li.first {
	-webkit-border-radius: 0 3px 0 0;
	-moz-border-radius: 0 3px 0 0;
	border-radius: 0 3px 0 0;
}

.navigation ul ul li.last {
	-webkit-border-radius: 0 0 3px 0;
	-moz-border-radius: 0 0 3px 0;
	border-radius: 0 0 3px 0;
	border-bottom: 0;
}

.navigation ul ul {
	-webkit-border-radius: 0 3px 3px 0;
	-moz-border-radius: 0 3px 3px 0;
	border-radius: 0 3px 3px 0;
}

.navigation ul ul { border: 1px solid #34A65F; }

.navigation ul ul a { color: #ffffff; }

.navigation ul ul a:hover { color: #ffffff; }

.navigation ul ul li { border-bottom: 1px solid #0F8A5F; }

.navigation ul ul li:hover > a {
	background: #4eb1ff;
	color: #ffffff;
}

.navigation.align-right > ul > li > a {
	border-left: 0.3em solid #34A65F;
	border-right: none;
}

.navigation.align-right { float: right; }

.navigation.align-right li { text-align: right; }

.navigation.align-right ul li.has-sub > a:before {
	content: '+';
	position: absolute;
	top: 50%;
	left: 15px;
	margin-top: -6px;
}

.navigation.align-right ul li.has-sub > a:after { content: none; }

.navigation.align-right ul ul {
	visibility: hidden; 
	position: absolute;
	top: 0;
	left: -100%;
	z-index: 598;
	width: 100%;
}

.navigation.align-right ul ul li.first {
	-webkit-border-radius: 3px 0 0 0;
	-moz-border-radius: 3px 0 0 0;
	border-radius: 3px 0 0 0;
}

.navigation.align-right ul ul li.last {
	-webkit-border-radius: 0 0 0 3px;
	-moz-border-radius: 0 0 0 3px;
	border-radius: 0 0 0 3px;
}

.navigation.align-right ul ul {
	-webkit-border-radius: 3px 0 0 3px;
	-moz-border-radius: 3px 0 0 3px;
	border-radius: 3px 0 0 3px;
}
.div_frame {
	width: 100%;
	vertical-align: top;
	display: table;
}
.div_frame_td1 {
	vertical-align: top;
	float: left;
	width: 25%;
}
.div_frame_td2 {
	vertical-align: top;
	width: 25%;
	display: inline-block;
}
.div_frame_td3 {
	vertical-align: top;
	width: 25%;
	display: inline-block;
}
.div_frame_td4 {
	vertical-align: top;
	width: 25%;
	display: inline-block;
}
.div_frame_td5 {
	vertical-align: top;
	width: 20%;
	display: inline-block;
}
.td_action {
	float: left;
	background: #333333;
	border: 1px solid #666666;
	padding: 15px;
	text-align: left;
	color: #FFFFFF;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	/* width: 100%; */
	user-select: none;
	display: inline;
}
@media only screen and (max-width: 1920px) {
	.td_action {
		font-size:15pt;
		padding: 15px;
		background: transparent;
		color: #000000;
		border: 0px solid #666666;
		border-bottom: 1px solid #CCCCCC;
	}	
}
.info_message {
	font-size:15pt;
	text-align:left;
	float:left;
	padding:10px;
}
.supplierHeader {	
	float: left;
	text-align: left;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	user-select: none;
	display: inline;	
	font-size:15pt;
	padding: 15px 0px;
	background: transparent;
	color: #000000;
	border: 0px solid #666666;
	border-bottom: 1px solid #CCCCCC;
}
.td_action_supplier {
	float: left;
	background: #333333;
	border: 1px solid #666666;
	padding: 15px;
	text-align: left;
	color: #FFFFFF;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	/* width: 100%; */
	user-select: none;
	display: inline;
}
@media only screen and (max-width: 1920px) {
	.td_action_supplier {
		font-size:15pt;
		padding: 3px 5px 3px 25px;
		background: transparent;
		color: #000000;
		/*
		border-top: 0px solid #666666;
		border-left: 1px solid #666666;
		border-right: 1px solid #666666;
		border-bottom: 1px solid #666666;
		*/
		border: 0px solid #666666;
	}	
}
.img_button {
	cursor: pointer;
	border:none;
	background:transparent;
}
.td_action_highlighted {
	float: left;
	background: #333333;
	border: 1px solid #666666;
	padding: 15px;
	text-align: left;
	color: #FFFFFF;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	/* width: 100%; */
	user-select: none;
	display: inline;
}
@media only screen and (max-width: 1920px) {
	.td_action_highlighted {
		font-size:15pt;
		padding: 15px;
		background: #CCCCCC;
		color: #000000;
		border: 0px dotted #666666;
		margin-bottom: 5px;
	}	
}
.td_pointer {
	cursor: pointer;
}
.td_floatright{
	margin-left:5px;
	float: right;
}
.td_action_sub {
	background: #313131;
	border: 1px solid #666666;
	padding: 10px;
	text-align: left;
	color: #FFFFFF;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	user-select: none;
	display: inline;
	float: right;
	width: 95%;
}

@media only screen and (max-width: 1920px) {
	.td_action_sub {
		background: transparent;
		color: #000000;
		border: 0px solid #666666;
		border-bottom: 1px solid #CCCCCC;
		font-size:15pt;
		padding: 10px;
	}
}
.sub_margin {
	margin-left:15px;
}
.but_margin {
	margin:0px 5px;
}
.td_action_selected {
	background: #CCCCCC;
}

.div_nav_button {
	padding-bottom:10px;
}
.td_action2 {
	background: #313131;
	border: 1px solid #666666;
	padding: 15px;
	text-align: left;
	color: #FFFFFF;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	user-select: none;
	display: inline;
	float: left;
	width: 100%;
}

.td_action2_selected {
	background: #818181;
}


.td_header {
	background: #636d0e;
	border: 1px solid #666666;
	padding: 5px 15px;
	float:left;
	text-align: left;
	color: #FFFFFF;
	cursor: default;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	/* width: 100%; */
	user-select: none;
	}
@media only screen and (max-width: 1920px) {
	.td_header {
		background: transparent;
		border: 0px solid #666666;
		padding: 5px 0px;
		font-size:15pt;
		color: #000000;
		font-weight: bold;
		text-transform: uppercase; 
	}
}

.td_par {
	background: #0e6d16;
	border: 1px solid #666666;
	padding: 5px 15px;
	text-align: left;
	color: #FFFFFF;
	cursor: default;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	/* width: 100%; */
	user-select: none;
}

.td_header_menucat {
	background: #127970;
	border: 1px solid #666666;
	padding: 5px 15px;
	margin-top: 10px;
	text-align: left;
	color: #FFFFFF;
	font-weight: bold;
	cursor: default;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	/* width: 100%; */
	user-select: none;
	float: left;
}
@media only screen and (max-width: 1920px) {	
	.td_header_menucat {
		color: #000000;
		background: transparent;
		padding: 15px 0px;
		border: 0px solid #666666;
		cursor: pointer;
		border-bottom: 1px solid #CCCCCC;
		text-transform: uppercase; 
	}
}
.orderlist_header{
	text-align:center;
	padding:20px;
	float:left;
}
.orderlist_header_green{
	background-color: #84c77e;
}
.orderlist_header_red{
	margin-top:10px;
	background-color: #f38e8e;
}
.orderlist_header_border{
	margin-top:10px;
	background-color: #CCCCCC;
	border: 1px solid #CCCCCC;
}
.span-sub-complete{
	white-space: nowrap;
	color: #5aa353;
}
.span-sub-awaiting{
	white-space: nowrap;
	color: #f31e1e;
}


@-webkit-keyframes blinker {
	from {
		background-color: #5abe51;
	}
	to {background-color: #fffdfd;}
  }
  .blink{
	  text-decoration: blink;
	  -webkit-animation-name: blinker;
	  -webkit-animation-duration: 0.6s;
	  -webkit-animation-iteration-count:infinite;
	  -webkit-animation-timing-function:linear;
	  -webkit-animation-direction: alternate;
  }

.td_MenuOrder_wrap {
	border-bottom:1px solid #999999;
	width: 100%;
	padding: 8px 0px;
	float: left;
	margin-top:5px;
}
.td_header_MenuOrder {
	background: #127970;
	border: 1px solid #666666;
	padding: 5px 15px;
	text-align: left;
	color: #FFFFFF;
	font-weight: bold;
	cursor: default;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	/* width: 100%; */
	user-select: none;
	float: left;
}
@media only screen and (max-width: 1920px) {	
	.td_header_MenuOrder {
		color: #000000;
		background: transparent;
		padding: 0px;
		/*
		border-top: 1px solid #666666;
		border-left: 1px solid #666666;
		border-right: 1px solid #666666;
		border-bottom: 0px solid #666666;
		*/
		border: 0px solid #666666;
	}
}

.td_header_MenuCategory {
	background: #127970;
	border: 1px solid #666666;
	padding: 5px 15px;
	text-align: left;
	color: #FFFFFF;
	cursor: default;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	/* width: 100%; */
	user-select: none;
	cursor: pointer;
	float: left;
}
@media only screen and (max-width: 1920px) {	
	.td_header_MenuCategory {
		color: #000000;
		background: transparent;
		padding: 15px 0px;
		font-size:15pt;
		/*
		border-top: 1px solid #666666;
		border-left: 1px solid #666666;
		border-right: 1px solid #666666;
		border-bottom: 0px solid #666666;
		*/
		border: 0px solid #666666;
		border-bottom: 1px solid #CCCCCC;
	}
}


.td_par2_sub {
	background: #127970;
	border: 1px solid #666666;
	padding: 5px 15px;
	text-align: left;
	color: #FFFFFF;
	cursor: default;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 95%;
	/* width: 100%; */
	user-select: none;
	float: right;
}
@media only screen and (max-width: 1920px) {	
	.td_par2_sub {
		color: #000000;
		background: transparent;
		padding:5px 0px;
		font-weight:bold;
		font-size:13pt;
		border: 0px solid #666666;
		margin-top:10px;
		text-transform: uppercase; 
	}
}
.td_confirm_inactive {
	background: rgb(207, 223, 211);
	border: 1px solid #CCCCCC;
	padding: 15px;
	text-align: left;
	color: #FFFFFF;
	cursor: default;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	width: 100%;
	/* width: 100%; */
	user-select: none;
}
.td_confirm {
	background: #0e6d16;
	border: 1px solid #666666;
	padding: 15px;
	text-align: left;
	color: #FFFFFF;
	cursor: default;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	cursor: pointer;
	width: 100%;
	/* width: 100%; */
	user-select: none;
}
.confirm_notice{
	width: 100%; 
}
.single-click{
	font-size:10pt;
	color: #5ef566;
	text-transform: uppercase; 
}
.span-clear{
	font-size:10pt;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	text-transform: uppercase; 
	font-weight: bold;
	color: #ffffff;
	cursor:pointer;
	text-align:right;
	/*float: right; */

}
.span-sub{
	font-size:10pt;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	font-weight: bold;
	color: #f0dfdf;
}
@media only screen and (max-width: 1920px) {
	.span-sub{
		color: #666666;
	}
}
.span-sub-black{
	font-size:10pt;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	font-weight: bold;
}
.span-order{
	font-size:11pt;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	font-weight: bold;
	color: #1afa52;
	cursor:pointer;
}
@media only screen and (max-width: 1920px) {
	.span-order{
		color: #066b20;
	}
}
.span-button{
	cursor:pointer;
}
.span-button:hover{
	text-decoration: underline;
}
.span-price{
	font-size:11pt;
	font-family: 'roboto', Tahoma, Arial, sans-serif;
	text-transform: uppercase; 
	vertical-align: top;
	font-weight: bold;
	color: #ffffff;
	float: right;
	margin-right:15px;
}
@media only screen and (max-width: 1920px) {	
	.span-price{
		color: #000000;
	}
}

.td_web_hide {
display: none;
}

@media only screen and (max-width: 1920px) {
.td_web_hide {
	display: block;
}
.td_action2 {
	font-size:20pt;
	padding: 20px;
}
.td_confirm {
	font-size:20pt;
	padding: 15px;
}
.td_confirm_inactive {
	font-size:20pt;
	padding: 15px;
}
.CommandPanel_header {
	display:none;
}

.CommandPanel_subheader {
	display:none;
}

.div_frame_td1 {
	width: 100%;
	float: none;
}
.div_frame_td2 {
	width: 100%;
	margin-left: 0%;
	float: none;
}
.div_frame_td3 {
	width: 100%;
	margin-left: 0%;
	float: none;
	vertical-align: top; /* here */
}
.div_frame_td4 {
	width: 100%;
	margin-left: 0%;
	float: none;
}

.td_tablet_hide {
	display:none; 
}
}
  