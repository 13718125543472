.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}
.td_logo {
  padding:0px 8px 0px 8px;
}
.subheader {
  font-size:20px;
  font-weight: bold;
}